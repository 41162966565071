import { InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterQueryDocument,
  FooterQueryQuery,
  FooterQueryQueryVariables,
  FormQueryDocument,
  FormQueryQuery,
  FormQueryQueryVariables,
  HomeCardsWithLinkDocument,
  HomeCardsWithLinkQuery,
  HomeCardsWithLinkQueryVariables,
  HomeHeroDocument,
  HomeHeroQuery,
  HomeHeroQueryVariables,
  HomeLargeImageDocument,
  HomeLargeImageQuery,
  HomeLargeImageQueryVariables,
  HomeNewsroomDocument,
  HomeNewsroomQuery,
  HomeNewsroomQueryVariables,
  HomeSeoDocument,
  HomeSeoQuery,
  HomeSeoQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { Homepage } from '@/views/homepage';

export const getStaticProps = async () => {
  const client = initializeApollo();

  // prefetch
  await client.query({ query: HomeSeoDocument });
  await client.query({ query: HomeHeroDocument });
  await client.query({ query: HomeCardsWithLinkDocument });
  await client.query({ query: HomeLargeImageDocument });
  await client.query({ query: HomeNewsroomDocument });
  await client.query({ query: FooterQueryDocument });
  await client.query({ query: NavigationDocument });
  await client.query({ query: FormQueryDocument });

  const { data: seoData } = await client.query<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >({ query: HomeSeoDocument });

  const { data: heroData } = await client.query<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >({
    query: HomeHeroDocument,
  });

  const { data: cardsData } = await client.query<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >({
    query: HomeCardsWithLinkDocument,
  });

  const { data: largeImageSectionData } = await client.query<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >({
    query: HomeLargeImageDocument,
  });

  const { data: newsroomData } = await client.query<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >({
    query: HomeNewsroomDocument,
  });

  const { data: formData } = await client.query<
    FormQueryQuery,
    FormQueryQueryVariables
  >({ query: FormQueryDocument });

  const { data: footerData } = await client.query<
    FooterQueryQuery,
    FooterQueryQueryVariables
  >({ query: FooterQueryDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({ query: NavigationDocument });

  return addApolloState(client, {
    props: {
      heroData,
      cardsData,
      largeImageSectionData,
      seoData,
      formData,
      footerData,
      headerData,
      newsroomData,
    },
  });
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  heroData,
  cardsData,
  largeImageSectionData,
  seoData,
  formData,
  footerData,
  headerData,
  newsroomData,
}) => (
  <Homepage
    seo={seoData?.home?.data?.attributes?.seo}
    hero={heroData.home?.data?.attributes?.heroVideoSection}
    cards={cardsData?.home?.data?.attributes?.imageCardsWithLink}
    largeImageSection={
      largeImageSectionData?.home?.data?.attributes?.largeImageSection
    }
    newsroom={newsroomData?.home?.data?.attributes?.newsroom}
    formData={formData}
    footerData={footerData}
    headerData={headerData}
  />
);

export default Home;
