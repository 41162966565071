import {
  LargeImageBackgroundType,
  LargeImageSection,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';

import { LargeImageSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface LargeImageProps {
  largeImageSection?: LargeImageSectionFragment | null;
  titleMaxWidth?: number;
  descriptionMaxWidth?: number;
  textCenter?: boolean;
}

export default function LargeImage({
  largeImageSection,
  titleMaxWidth,
  descriptionMaxWidth,
  textCenter,
}: LargeImageProps) {
  const largeImageSectionProps = {
    textCenter,
    titleMaxWidth,
    descriptionMaxWidth,
    image: {
      imgSrc: getCdlPublicId(
        largeImageSection?.image?.img_desktop.data?.attributes?.url || '',
      ),
      imgSrcMobile: getCdlPublicId(
        largeImageSection?.image?.img_mobile?.data?.attributes?.url ||
          undefined,
      ),
      alt: largeImageSection?.image?.alt || '',
      blurHash:
        largeImageSection?.image?.img_desktop.data?.attributes?.blurhash ||
        '12312312',
    },
    bgColor: 'light-blue' as LargeImageBackgroundType,
    title: largeImageSection?.title || '',
    description: largeImageSection?.description || '',
    button: {
      label: largeImageSection?.button?.label || '',
      path: largeImageSection?.button?.path || '',
      useCase: 'technology' as useCaseType,
    },
  };

  return <LargeImageSection {...largeImageSectionProps} />;
}
